do ($ = jQuery) ->
  $ ->
    $('.field-collection-item-field-bubble').each ->
      link = $(this).find('a')
      link.attr('title', $(this).find('h3').text())
      $(this).find('h3').wrapInner(link.clone().empty())
      $(this).find('img').wrap(link.clone().empty())

    $('.view-current-vacancies .views-table tr').css('cursor', 'pointer').click ->
      $(this).find('a')[0].click();
